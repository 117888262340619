import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import {map, Observable} from "rxjs";
import { RestaurantModel } from "../models/interfaces/restaruant.model";
import { PaginationModel } from "../models/interfaces/pagination.model";
import { IRequestOptions } from "./utils.service";
import { CardItem } from "../models/interfaces/card-item.model";

@Injectable({
  providedIn: 'root'
})

export class RestaurantService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  /*-- Get Random Restaurants --*/
  getRandomRestaurants(pagination: PaginationModel): Observable<RestaurantModel[]>{
    const endpoint: string = this.getEndpoint( BaseService.ENDPOINT_NAME_GET_RANDOM_RESTAURANTS );
    let queryParams: HttpParams = new HttpParams();
    queryParams = queryParams.append('Skip' , pagination?.take * (pagination?.page - 1));
    queryParams = queryParams.append('Take' , pagination?.take);
    queryParams = queryParams.append('Seed' , pagination?.seed);
    let options = {params:queryParams, withCredentials:true} as IRequestOptions;
    return this.http.get<RestaurantModel[]>(endpoint , options );
  }

  /*-- Get Random Restaurant Details --*/
  getRestaurantDetail(id: number): Observable<RestaurantModel>{
    const endpoint: string = this.getEndpoint( BaseService.ENDPOINT_NAME_GET_RESTAURANT_DETAIL );
    let queryParams: HttpParams = new HttpParams();
    queryParams = queryParams.append('id', id);
    let options = {params:queryParams, withCredentials:true} as IRequestOptions;
    return this.http.get<RestaurantModel>(endpoint , options );
  }

  addToFavorites(restaurant_id: number): Observable<any> {
    const endpoint: string = this.getEndpoint(BaseService.ENDPOINT_NAME_ADD_TO_FAVORITES_RESTAURANT);
    let options = { withCredentials:true } as IRequestOptions;
    return this.http.post<any>(endpoint, {
      entityId: restaurant_id,
    }, options);
  }


}
